import React, { useState, useEffect } from 'react';
import { Button, Table, Thead, Tbody, Tr, Th, Td, chakra, Box, HStack, Image, Text } from '@chakra-ui/react'
import { useTable, useSortBy } from 'react-table'

import TableHead from '../GlobalComponents/TableHead';
import BaseModal from '../GlobalComponents/BaseModal';


export default function ImageBox(props) {

    return (
        <BaseModal isOpen={props.isOpen} onClose={() => props.ImageCall()} 
            style={{display: 'flex', width: '500px', minHeight: '250px', maxHeight: '80%', flexDirection: 'column', alignItems: 'center', padding: 3, overflow: 'auto'}}
        >
                <Box display='flex' flexDirection='row'>
                    <Text fontSize={25} fontWeight='bold' padding={1}>Zugestellt an: </Text>
                    <Text fontSize={25} border='0.5px solid gray' padding={1} ml={3}>{props.curentReci}</Text>
                </Box>

                <Box display='flex' flexDirection='column' mt={3} p={3} overflowY="auto">

                    {props.ImageUri.map((item) => (

                        <Box borderWidth={3} margin={1} key={item.uri} w='17vw'>
                            {/*<Image src={props.server + '/API/tmp/' + item.uri} alt={item.uri} objectFit='contain' />*/}
                            {item.uri && item.uri !== null ? (
                                
                                <Image 
                                    src={props.server + '/API/' + props.ImageDir + item.uri} 
                                    alt={item.uri}
                                    objectFit='contain'
                                />

                            ) : (
                                <Text fontSize={20} >Kein Bild hinterlegt!</Text>
                            )}
                        </Box>
                    ))}
                </Box>
        </BaseModal>
    );
}