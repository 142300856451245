import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Text, Button, Icon, Flex, useToast, Center, Input, Select, InputGroup, InputRightElement, VStack, Tooltip, Spacer, Alert, Square, IconButton } from '@chakra-ui/react'
import { RiCloseFill } from "react-icons/ri";



export const ManifestFilterComp = forwardRef((props, ref) => {

    const ManifFilterRef = useRef(null);
    const TourFilterRef = useRef(null);
    const TeamFilterRef = useRef(null);
    const DriverFilterRef = useRef(null);

    useImperativeHandle(ref, () => ({
        Reciever,
    }));

    async function Reciever(comand, value1, value2, value3) {
        switch (comand) {
            case 'makeFilter': //creates FilterData for Dropdowns
                MakeFilterValues(value1);
                break;
            case 'filterData': //filter Manifeste by set Filters
                return filterData(value1);
            case 'clearFilter': //clear FilterValue on Reload
                filterClear();
                break;
            case 'setFilter': //set FilterValue on return
                SetFilterValues(value1);
                break;
            default:
                return;
        }
    }
    async function SetFilterValues(values) {

        if (ManifFilterRef.current) ManifFilterRef.current.value = values.Manifest;
        if (TourFilterRef.current) TourFilterRef.current.value = values.Tour;
        if (TeamFilterRef.current) TeamFilterRef.current.value = values.Team;
        if (DriverFilterRef.current) DriverFilterRef.current.value = values.Driver;

    }
    async function MakeFilterValues(ResultArray) {

        var DriverFilterArray = [];
        var TeamFilterArray = [];
        var TourFilterArray = [];

        if (Array.isArray(ResultArray.Tours)) {
            TourFilterArray = ResultArray.Tours.map(str => ({ label: str, value: str }));
        }
        if (Array.isArray(ResultArray.Teams)) {
            TeamFilterArray = ResultArray.Teams.map(item => ({ label: item.Team_Name, value: item.Team_ID }));
        }
        if (Array.isArray(ResultArray.Drivers)) {
            DriverFilterArray = ResultArray.Drivers.map(item => ({ label: item.User_Name, value: item.User_ID }));
        }

        props.FilterCall('setPersData', { Tours: TourFilterArray, Teams: TeamFilterArray, Drivers: DriverFilterArray })

        filterClear();
    }
    async function filterData(fetchData = null) {

        if (fetchData === null) { var newArray = props.ManifestArrayOrigin } else { var newArray = fetchData }

        if (ManifFilterRef.current && ManifFilterRef.current.value !== '') {
            newArray = await newArray.filter(item => item.Manifestnummer.includes(parseInt(ManifFilterRef.current.value)));
        }
        if (TourFilterRef.current && TourFilterRef.current.value !== '') {
            newArray = await newArray.filter(item => item['Tour'] == TourFilterRef.current.value);
        }
        if (DriverFilterRef.current && DriverFilterRef.current.value !== '') {
            newArray = await newArray.filter(item => item['Fahrer_ID'] == DriverFilterRef.current.value);
        }
        if (TeamFilterRef.current && TeamFilterRef.current.value !== '') {
            newArray = await newArray.filter(item => item.Team_IDs !== null && item.Team_IDs !== undefined &&
                item.Team_IDs.split(", ").map(Number).includes(parseInt(TeamFilterRef.current.value))
            )
        };
        return newArray
    }
    async function filterDataControll(type, value) {

        props.FilterCall('newSelected', type, value);

        if (Array.isArray(props.ManifestArrayOrigin)) {

            var filtered = await filterData(props.ManifestArrayOrigin)
            props.FilterCall('filtered', filtered);
        }
    }
    async function filterClear() {

        if (ManifFilterRef.current) ManifFilterRef.current.value = '';
        if (TourFilterRef.current) TourFilterRef.current.value = '';
        if (DriverFilterRef.current) DriverFilterRef.current.value = '';
        if (TeamFilterRef.current) TeamFilterRef.current.value = '';

    }
    return (

        <>
            {!props.HideTourFilter && props.PersFilterData.Tours.length > 1 ? (
                <Flex m={2} width='220px' height='40px' border='0px solid black' alignItems='center' borderRadius={6} pl={1} bg='#1DA1F2'>
                    <Text ml={1} color='#fff' fontWeight='semibold' fontSize={17} width='25%'>Tour:</Text>
                    <Flex width='75%' height='40px' alignItems='center'>
                        <Select ref={TourFilterRef} placeholder='...' border='0px solid black' borderRadius={0} fontSize={17} bg='#fff' height='36px'
                            onChange={(event) => [filterDataControll('Tour', event.target.value)]}>
                            {props.PersFilterData.Tours.map((row) => { return (<option key={row.value} value={row.value} style={{ color: 'black' }}>{row.label}</option>) })}
                        </Select>
                    </Flex>
                    <IconButton bg='#1DA1F2' borderRadius='0px 5px 5px 0px' color='#fff' width='40px'
                        onClick={() => [TourFilterRef.current.selectedIndex = 0, filterDataControll('Tour', '')]}
                        icon={<Icon as={RiCloseFill} color='#fff' boxSize={35} />} />
                </Flex>
            ) : (null)}
            {!props.HideTeamFilter && props.PersFilterData.Teams.length > 1 ? (
                <Flex m={2} width='260px' height='40px' border='0px solid black' alignItems='center' borderRadius={6} pl={1} bg='#1DA1F2'>
                    <Text ml={1} color='#fff' fontWeight='semibold' fontSize={17} width='25%'>Team:</Text>
                    <Flex width='75%' height='40px' alignItems='center'>
                        <Select ref={TeamFilterRef} placeholder='...' border='0px solid black' borderRadius={0} fontSize={17} bg='#fff' height='36px'
                            onChange={(event) => [filterDataControll('Team', event.target.value)]}>
                            {props.PersFilterData.Teams.map((row) => { return (<option key={row.value} value={row.value} style={{ color: 'black' }}>{row.label}</option>) })}
                        </Select>
                    </Flex>
                    <IconButton bg='#1DA1F2' borderRadius='0px 5px 5px 0px' color='#fff' width='20px'
                        onClick={() => [TeamFilterRef.current.selectedIndex = 0, filterDataControll('Team', '')]}
                        icon={<Icon as={RiCloseFill} color='#fff' boxSize={35} />} />
                </Flex>
            ) : (null)}
            {!props.HideDriverFilter && props.PersFilterData.Drivers.length > 1 ? (
                <Flex m={2} width='300px' height='40px' border='0px solid black' alignItems='center' borderRadius={6} pl={1} bg='#1DA1F2'>
                    <Text ml={1} color='#fff' fontWeight='semibold' fontSize={17} width='25%'>Fahrer:</Text>
                    <Flex width='75%' height='40px' alignItems='center'>
                        <Select ref={DriverFilterRef} placeholder='...' border='0px solid black' borderRadius={0} fontSize={17} bg='#fff' height='36px'
                            onChange={(event) => [filterDataControll('Driver', event.target.value)]}>
                            {props.PersFilterData.Drivers.map((row) => { return (<option key={row.value} value={row.value} style={{ color: 'black' }}>{row.label}</option>) })}
                        </Select>
                    </Flex>
                    <IconButton bg='#1DA1F2' borderRadius='0px 5px 5px 0px' color='#fff' width='20px'
                        onClick={() => [DriverFilterRef.current.selectedIndex = 0, filterDataControll('Driver', '')]}
                        icon={<Icon as={RiCloseFill} color='#fff' boxSize={35} />} />
                </Flex>
            ) : (null)}

            <Flex m={2} width='250px' height='40px' border='0px solid black' alignItems='center' borderRadius={6} pl={1} bg='#1DA1F2'>
                <Text ml={1} color='#fff' fontWeight='semibold' fontSize={17} width='40%'>Manifest:</Text>
                <Flex width='60%' height='40px' alignItems='center'>
                    <Input border='0px solid black' borderRadius={0} fontSize={17} bg='#fff' height='36px' type='number'
                        ref={ManifFilterRef} onChange={(event) => [filterDataControll('Manifest', event.target.value)]}></Input>
                </Flex>
                <IconButton bg='#1DA1F2' borderRadius='0px 5px 5px 0px' color='#fff' width='40px'
                    onClick={() => [ManifFilterRef.current.value = '', filterDataControll('Manifest', '')]}
                    icon={<Icon as={RiCloseFill} color='#fff' boxSize={35} />} />
            </Flex>

        </>
    )
}
)

