import React, { useState, useEffect, useRef } from 'react';
import GlobalController from './GlobalComponents/GlobalController';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate, useLocation } from 'react-router-dom';

function App() {

    return (
        <React.StrictMode>
            <Router
            //basename="/TourPort" //debug version
            // release version
            >
                <GlobalController />
            </Router>
        </React.StrictMode>
    )
    //return <Home/>
}

export default App;


