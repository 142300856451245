import React, { useState, useEffect, useRef } from 'react';
import '../css/App.css';

import { SimpleGrid, Box, useToast, Flex, Text, Center, Button, Icon, Input, InputRightElement, InputGroup } from '@chakra-ui/react'
import { FaUser, FaEyeSlash, FaEye } from 'react-icons/fa'
import Cookies from 'universal-cookie';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';

import SearchManifest from '../ManageTour/ManifestSucheHandler.js';
import WebsiteHeader from '../GlobalComponents/WebsiteHeader.js';
import DevicePannel from '../ManageDevice/DevicePanel.js';
import UserPannel from '../ManageUser/UserPannel.js'
import ChacraAlert from './ChakraAlert.js';
import TeamsTable from '../ManageUser/TeamsTable.js';
import PaketSuche from '../ManageTour/PaketSuche/PaketSuche.js';
import { ErrorToast, WarnToast, CheckToast } from './CustomToast.js'
import MultiPaketSuche from '../ManageTour/PaketSuche/MultiPaketSuche.js';


export default function GlobalController() {
    const toast = useToast();
    const nameRef = useRef(null)
    const passRef = useRef(null)

    const [Dummy, setDummy] = useState('');

    const [show, setShow] = useState(false)

    const [ActiveUser, setActiveUser] = useState({});
    const [UserTeams, setUserTeams] = useState({});
    const [UserRights, setUserRights] = useState({});

    const [GlobalDataPackage, setGlobalDataPackage] = useState({});

    const [chacraAlert, setChacraAlert] = useState({ isOpen: false, header: 'Header', text: 'Description', closeBtn: 'cancel', actBtn: 'action', onClose: null, onAction: null });

    //const [server] = useState('http://192.168.254.40/TourPort') //Update PDO on API Update!
    const [server] = useState('https://tourmanager.kreller.de')
    const [baseRoute] = useState('');

    const SectionStyle = {
        borderWidth: 1,
        borderRadius: 3,
        borderColor: '#000',
        backgroundColor: '#fff',
        margin: 15,
        width: '20%',
        height: '80%',
        justifyContent: 'flex-start',
        flexDirection: 'column',
    }
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams(); //read and set query-params


    useEffect(() => {
        RelogHandler();
    }, [])

    async function _sendData(method, params, body) {
        try {
            const response = await fetch(server + '/API/index.php/' + method + params
                , {
                    method: 'POST',
                    body: JSON.stringify(body)
                });
            const data = await response.json();
            //console.log(data)
            return data;
        } catch (error) {
            //console.log(error)
            //alert('Verbindung Fehlgeschlagen!');
            return false
        }
    }
    async function verifyToken(UserToken) {
        try {
            const response = await fetch(server + '/API/index.php/Web_TokenLogin', {
                method: 'POST',
                //headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Basic VHJhdmVsZXI6VGhpczEhSXMyIlRoZTPCp1Bhc3N3b3JkNCQ=' },
                body: JSON.stringify({ Token: UserToken })
            });
            const results = await response.json();
            return results;
        } catch (error) {
            //console.log(error);
            alert('Verbindung Fehlgeschlagen!'); return 'error'
        }
    }
    function PreferMapStyle(value1) {
        var User = { ...ActiveUser };
        if (!User.User_Prefers) User.User_Prefers = {};
        User.User_Prefers.MapStyle = value1;
        setActiveUser(User);
        //send Update to Server
        UpdatePrefers(User.User_ID, User.User_Prefers)
    }
    function PreferLieferFirst(value1) {
        var User = { ...ActiveUser };
        if (!User.User_Prefers) User.User_Prefers = {};
        User.User_Prefers.ShowLieferFirst = value1;
        setActiveUser(User);
        //send Update to Server
        UpdatePrefers(User.User_ID, User.User_Prefers)
    }
    function createRights(Rights) {
        var newRights = {}
        Rights.forEach(item => {
            newRights[item] = true;
        });
        setUserRights(newRights)
        //console.log(UserRights)
    }
    function UnsetRights() {
        setUserTeams([])
        setUserRights({});
        setActiveUser({});
    }
    async function Login(DeviceID) {
        try {

            var UserText = nameRef.current.value;
            var PasswordText = passRef.current.value;

            const response = await fetch(server + '/API/index.php/Web_UserLogin', {
                method: 'POST',
                //headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Basic VHJhdmVsZXI6VGhpczEhSXMyIlRoZTPCp1Bhc3N3b3JkNCQ=' },
                body: JSON.stringify({ User: UserText, Password: PasswordText, DeviceID: DeviceID, Acces_Type: 'Web' })
            });
            const results = await response.json();

            //console.log(results)

            return results;

        } catch (error) {
            console.log(error)
            alert('Verbindung Fehlgeschlagen!'); return "error";
        }
    }
    async function RelogHandler() {
        //console.log('reload')
        const cookies = new Cookies();
        var UserToken = cookies.get('LoginToken');

        if (UserToken) {
            var LoginArray = await verifyToken(UserToken);
            if (LoginArray !== 'denied' && LoginArray !== 'error' && LoginArray !== 'invalid') {

                console.log(LoginArray)
                setActiveUser(LoginArray.User)
                createRights(LoginArray.Right)
                setUserTeams(LoginArray.Team)
            }
        }
    }
    async function Loginhandler() {

        var BrowserID = await getCurrentBrowserFingerPrint()
        var LoginArray = await Login(BrowserID);
        if (LoginArray !== 'denied' && LoginArray !== 'error' && LoginArray !== 'invalid') {

            const cookies = new Cookies();
            cookies.set('LoginToken', LoginArray.Token, { path: '/', expires: new Date(Date.now() + (365 * 24 * 60 * 60 * 1000)) });
            ////console.log(cookies.get('UserToken'));
            setActiveUser(LoginArray.User)
            createRights(LoginArray.Right)
            setUserTeams(LoginArray.Team)
        } else {
            toast({
                render: () => (<ErrorToast title='Anmeldedaten fehlerhaft!' descr="Benutzername oder Passwort sind falsch" />),
                status: 'error', duration: 10000, isClosable: true, position: 'top',
            })

            nameRef.current.focus();
        }
    }
    async function LogoutHandler() {
        const cookies = new Cookies();
        var UserToken = cookies.get('LoginToken');
        var method = 'Web_TokenLogout';
        var body = { Token: UserToken }
        await _sendData(method, '', body)
        var UserToken = cookies.remove('LoginToken');
        UnsetRights();
    }
    async function UpdatePrefers(User, Prefers) {
        const cookies = new Cookies();
        var UserToken = cookies.get('LoginToken');
        var method = 'Web_UpdatePrefers';
        var body = { Token: UserToken, User_ID: User, User_Prefers: Prefers }
        await _sendData(method, '', body)
    }
    function LogoutToast() {
        LogoutHandler();

        toast({ render: () => (<WarnToast title='Login-Token abgelaufen!' descr="Sie wurden vom System abgemeldet. Bitte melden Sie sich erneut an." />), status: 'error', duration: 10000, isClosable: true, position: 'top', })
    }
    function searchCall(comand, value1, value2) {
        if (comand === 'back') { navigate(baseRoute + '/') }
        if (comand === 'logOut') { LogoutToast() }
        if (comand === 'newRights') { createRights(value1) }
        if (comand === 'setMap') { PreferMapStyle(value1) }
        if (comand === 'setFirst') { PreferLieferFirst(value1) }
        if (comand === 'globalData' && typeof value1 == 'string') {

            var EditGlobalData = [...GlobalDataPackage];
            EditGlobalData[value1] = value2;
            setGlobalDataPackage(EditGlobalData);
        }
    }
    function LogoutAlert() {
        var Alert = { ...chacraAlert };
        Alert.isOpen = true;
        Alert.header = 'Benutzer Abmelden!';
        Alert.text = 'Möchten Sie sich wirklich vom Portal abmelden?';
        Alert.closeBtn = 'Abbrechen'
        Alert.actBtn = 'Abmelden'
        Alert.onClose = () => closeAlert();
        Alert.onAction = () => [closeAlert(), LogoutHandler('back')];
        setChacraAlert(Alert)
    }
    function closeAlert() {
        var Alert = { ...chacraAlert }; Alert.isOpen = false; setChacraAlert(Alert)
    }

    const handleClick = () => setShow(!show)

    function loginScreen() {

        return (
            <Flex height='100vh' flexDirection='column' >
                <WebsiteHeader Text={'TourPortal / Login'} User={ActiveUser} />
                <Flex bg='#ccc' flex={1} alignItems='center' justifyContent='center' >
                    <Center h='30%' minH='200px' w='30%' minW='350px' bg='#fff' borderWidth={1} borderRadius={5} borderColor='#000' flexDirection='column' p={5} justifyContent='space-evenly'>
                        <Input autoFocus placeholder='Benutzer-Kürzel' size='md' w='90%' fontSize='22px'
                            onKeyDown={(e) => (e.key === 'Enter' ? passRef.current.focus() : null)} ref={nameRef} />
                        <InputGroup borderColor='#000' size='md' w='90%'>
                            <Input
                                ref={passRef}
                                pr='4.5rem' type={show ? 'text' : 'password'} fontSize='22px' placeholder='Passwort'
                                onKeyDown={(e) => (e.key === 'Enter' ? Loginhandler() : null)}
                            />
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={handleClick}>
                                    {!show ? (<Icon as={FaEyeSlash} color='#000' boxSize='22px' />) : (<Icon as={FaEye} color='#000' boxSize='22px' />)}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <Button colorScheme='blue' h='40px' w='90%' onClick={() => Loginhandler()}>
                            <Text fontWeight='bold' fontSize='25px' >Login</Text>
                        </Button>
                    </Center>
                </Flex>
            </Flex>
        )
    }
    function returnSection() {

        if (!ActiveUser?.User_ID) {

            return loginScreen();

        } else {

            return (

                <Routes>
                    <Route path={"/"} element={

                        <Flex height='100vh' flexDirection='column' >
                            <ChacraAlert Alert={chacraAlert} />
                            <WebsiteHeader Text="TourPortal" onClick={() => LogoutAlert()} User={ActiveUser} server={server} />
                            <Flex bg='#ccc' flex={1} alignItems='center' justifyContent='center' >

                                <Center style={SectionStyle}>
                                    <Button my={7} w='90%' h='70px' onClick={() => navigate(baseRoute + '/manifeste')}
                                        colorScheme='twitter' >
                                        <Text fontWeight='bold' fontSize='30px'>Manifest Suche</Text>
                                    </Button>
                                    {/*
                            <Button my={7} w='90%' h='70px' onClick={() => navigate(baseRoute + '/paketsuche')}
                                isDisabled={!UserRights.tour_view_all} 
                                colorScheme='twitter'>
                                <Text fontWeight='bold' fontSize='30px'>Paket Suche</Text>
                            </Button>
                                            */}
                                    <Button my={7} w='90%' h='70px' onClick={() => navigate(baseRoute + '/multiPaketSuche')}
                                        isDisabled={!UserRights.tour_view_all} colorScheme='twitter' whiteSpace='normal'>
                                        <Text fontWeight='bold' fontSize='30px' flexShrink={1} overflowWrap='normal'>Multi-Paket Suche</Text>
                                    </Button>
                                </Center>
                                <Center style={SectionStyle}>
                                    <Button my={7} w='90%' h='70px' onClick={() => navigate(baseRoute + '/geräte')}
                                        isDisabled={!UserRights.device_manage} colorScheme='twitter'>
                                        <Text fontWeight='bold' fontSize='30px'>Geräte verwalten</Text>
                                    </Button>
                                </Center>
                                <Center style={SectionStyle}>
                                    <Button my={7} w='90%' h='70px' onClick={() => navigate(baseRoute + '/userpannel')}
                                        isDisabled={!UserRights.user_view} colorScheme='twitter'>
                                        <Text fontWeight='bold' fontSize='30px'>Nutzer verwalten</Text>
                                    </Button>
                                    <Button my={7} w='90%' h='70px' onClick={() => navigate(baseRoute + '/teampannel')}
                                        isDisabled={!UserRights.user_view} colorScheme='twitter' whiteSpace='normal' >
                                        <Text fontWeight='bold' fontSize='30px' flexShrink={1} overflowWrap='normal' >Teams und Rollen verwalten</Text>
                                    </Button>
                                </Center>
                                <Center style={SectionStyle}>

                                    <Text mt={10} fontSize={20}>Version: 2.8.01 debug</Text>
                                    {/* change on .40*/}
                                </Center>

                            </Flex>
                        </Flex>
                    } />
                    <Route path="manifeste/*" element={
                        <SearchManifest searchCall={searchCall} UserRights={UserRights} ActiveUser={ActiveUser} UserTeams={UserTeams} server={server}
                            route={baseRoute + "/manifeste"} />
                    } />
                    <Route path="paketsuche/*" element={
                        <PaketSuche searchCall={searchCall} UserRights={UserRights} ActiveUser={ActiveUser} UserTeams={UserTeams} server={server}
                            route={baseRoute + "/paketsuche"} />
                    } />
                    <Route path="multiPaketSuche/*" element={
                        <MultiPaketSuche searchCall={searchCall} UserRights={UserRights} ActiveUser={ActiveUser} UserTeams={UserTeams} server={server}
                            route={baseRoute + "/resetpaketsuche"} />
                    } />
                    <Route path="geräte/*" element={
                        <DevicePannel searchCall={searchCall} UserRights={UserRights} ActiveUser={ActiveUser} UserTeams={UserTeams} server={server}
                            route={baseRoute + "/geräte"} />
                    } />
                    <Route path="userpannel/*" element={
                        <UserPannel searchCall={searchCall} UserRights={UserRights} ActiveUser={ActiveUser} UserTeams={UserTeams} server={server}
                            route={baseRoute + "/userpannel"} />
                    } />
                    <Route path="teampannel/*" element={
                        <TeamsTable searchCall={searchCall} UserRights={UserRights} ActiveUser={ActiveUser} UserTeams={UserTeams} server={server}
                            route={baseRoute + "/teampannel"} />
                    } />
                    {/*
                <Route path="*" element={
                    <>
                        <Text>basePath failed</Text>
                        <Button my={7} w='90%' h='70px' onClick={() => navigate(baseRoute + '/')} colorScheme='orange'>
                                <Text fontWeight='bold' fontSize='30px'>Reset</Text>
                        </Button>
                    </>
                }/>
                */}

                    <Route path="*" element={<Navigate to={baseRoute + "/"} />} />

                </Routes>

            )
        }
        {/*
        if (section === 'ManifestSuche') {
            return (
                <SearchManifest searchCall={searchCall} UserRights={UserRights} ActiveUser={ActiveUser} UserTeams={UserTeams} server={server} />
            )
        }
        if (section === 'PaketSuche') {
            return (
                <PaketSuche searchCall={searchCall} UserRights={UserRights} ActiveUser={ActiveUser} UserTeams={UserTeams} server={server} />
            )
        }
        if (section === 'MultiPaketSuche') {
            return (
                <MultiPaketSuche searchCall={searchCall} UserRights={UserRights} ActiveUser={ActiveUser} UserTeams={UserTeams} server={server} />
            )
        }
        if (section === 'DeviceManage') {
            return (
                <DevicePannel searchCall={searchCall} UserRights={UserRights} ActiveUser={ActiveUser} UserTeams={UserTeams} server={server} />
            )
        }
        if (section === 'UserManage') {
            return (
                <UserPannel searchCall={searchCall} UserRights={UserRights} ActiveUser={ActiveUser} UserTeams={UserTeams} server={server} />
            )
        }
        if (section === 'TeamsTable') {
            return (
                <TeamsTable searchCall={searchCall} UserRights={UserRights} ActiveUser={ActiveUser} UserTeams={UserTeams} server={server} />
            )
        }
        */}
    }

    return (
        <>
            <div className='App'>

                {returnSection()}

            </div >
        </>
    );
}


